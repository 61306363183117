<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.working_time") }}
      <v-spacer></v-spacer>
      <v-btn small color="success" class="mr-2" outlined @click="save">{{
        $t("labels.save")
      }}</v-btn>
      <v-btn small color="error" outlined @click="cancel">{{
        $t("labels.close")
      }}</v-btn>
    </v-card-title>
    <v-card-text>
      <v-simple-table class="table-padding-2-no-top">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.select") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.shift") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.start") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.end") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="`${item.id}_${index}`"
              class="text-center"
            >
              <td>
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="selecteds"
                    class="mt-0 ml-1"
                    :value="item.id"
                    hide-details
                    multiple
                  ></v-checkbox>
                </div>
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.start }}</td>
              <td>{{ item.end }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "WorkingTimeList",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
    selecteds: [],
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getList();
    this.getSelected();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    getList: debounce(function () {
      httpClient
        .post("/working-time-all", { ...this.filters })
        .then(({ data }) => {
          this.items = [...data];
        });
    }, 500),
    getSelected: debounce(function () {
      httpClient
        .post("/office-working-time-list", { id_source: this.item.id })
        .then(({ data }) => {
          this.selecteds = [...data].map((d) => d.id_working_time);
        });
    }, 500),
    async save() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/office-working-time-save", {
          id_source: this.item.id,
          id_working_times: this.selecteds,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
